import { Col, Container, Row } from 'react-bootstrap';
import React, { useContext } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { GameLogo } from '../../../../game-document/game-logo';
import { GameName } from '../../../../game-document/game-name';
import { GameHeader } from '../../../../game-document/game-header';
import { GameDescription } from '../../../../game-document/game-description';
import { GameMedia } from '../../../../game-document/game-media';
import { GameContext } from '../../../../../contexts/game';
import { GameStatus } from '../../../../../types/state';
import { OverlayContext } from '../../../../../contexts/overlay';
import { PlayerContext } from '../../../../../contexts/player';
import { UpdatePlayerContext } from '../../../../../utils/player-state';
import { GameDocumentContext } from '../../../../../contexts/game-document';
import { generateTitleById } from '../../../../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../../../../contexts/display-languages';
import { useGame } from '../../../../../hooks/use-game';

export const Info = () => {
  const { gameCode } = useParams();
  const [gameDocument] = useContext(GameDocumentContext);
  const [game] = useContext(GameContext);
  const [, setOverlay] = useContext(OverlayContext);
  const [player, setPlayer] = useContext(PlayerContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const { data } = useGame(gameCode!);

  const gameStatus = ((game.gameState?.status === 'PreGame'
    ? data?.gameStatus
    : game.gameState?.status) || 'PreGame') as GameStatus;
  const playerStatus = player.playerState?.status || 'waiting';

  const navigate = useNavigate();
  const handleEnterGame = () => {
    setOverlay((prevState) => ({
      ...prevState,
      activeTab: 'info',
      drawerIsOpen: false
    }));

    setPlayer((prev) =>
      UpdatePlayerContext(prev, {
        ...prev.playerState!,
        status: 'playing',
        joinedDate: !prev?.playerState?.joinedDate
          ? new Date()
          : prev?.playerState?.joinedDate
      })
    );

    navigate(`/games/${gameCode}/map`);
  };

  const isDisableEnterGame = React.useMemo(() => {
    const isTeamEmpty = !Boolean(player.playerState?.teamCode);
    const mustJoinTeam =
      gameDocument.gameDocument?.settings.preGame.isPreGameTeamRequired &&
      isTeamEmpty;

    return (
      ['Paused', 'PreGame', 'Finished'].includes(gameStatus) ||
      ['playing', 'kicked', 'finished'].includes(playerStatus) ||
      mustJoinTeam
    );
  }, [
    gameStatus,
    playerStatus,
    player.playerState?.teamCode,
    gameDocument.gameDocument?.settings.preGame.isPreGameTeamRequired
  ]);

  return (
    <>
      <Container className={'drawer__body'} fluid>
        <Row>
          <GameHeader />
        </Row>
        <Row>
          <Col className={'py-3 bg-success position-relative'}>
            <GameLogo
              className={
                'event-image position-absolute w-5 h-5 rounded-circle border border-5 border-dark bg-white'
              }
            />
            <p className={'mb-0 pl-12 ml-20  text-white'}>
              <strong>
                <GameName />
              </strong>
            </p>
          </Col>
        </Row>
        <Row className={'bg-white pt-5 pb-4'}>
          <Col xs={12} md={8} className={'ps-4'}>
            <GameDescription />
          </Col>
          <Col xs={12} md={4}>
            <GameMedia className={'me-2'} />
          </Col>
        </Row>
      </Container>

      <Container className={'drawer__footer p-0 text-center py-2'} fluid>
        <Row>
          <Col>
            <Button
              className={'w-100'}
              themeColor={'success'}
              size={'large'}
              onClick={handleEnterGame}
              disabled={isDisableEnterGame}>
              {generateTitleById(
                'e4d66a9d-ed75-443e-82f1-9a8da53325d8',
                gameDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Enter game'}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
