import React from 'react';
import { useAuth } from 'react-oidc-context';

export const UserCard = () => {
  const auth = useAuth();
  return (
    <>
      {auth.user && (
        <div
          className={'header__user-card'}
          title={`Expires in ${auth.user.expires_in} seconds`}
          onClick={() => auth.signinSilent()}>
          <div className={'user-card__name'}>
            {auth.user.profile.name ?? ''}
          </div>
          <div className={'user-card__org'}>
            <>{(auth.user?.profile?.catalyst_org_name as string) ?? ''}</>
          </div>
        </div>
      )}
    </>
  );
};

export default UserCard;
