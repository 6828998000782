import { useMediaQuery } from 'usehooks-ts';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import React, { useContext, useMemo, useState } from 'react';

import { PlayerAvatar } from '../player-avatar';
import ProgressTimer from '../../progress-timer';
import { Icon } from '@progress/kendo-react-common';
import { PlayerContext } from '../../../contexts/player';
import { OverlayContext } from '../../../contexts/overlay';
import { GameDocumentContext } from '../../../contexts/game-document';

interface MenuProps {
  isNewChat: boolean;
  handleOpenChat: () => void;
}

interface IconMenuProps {
  isNewChat?: boolean;
  icon: string;
  onClick: () => void;
}

const IconMenu: React.FC<IconMenuProps> = ({ icon, isNewChat, onClick }) => {
  return (
    <Button
      className={'text-white'}
      fillMode={'flat'}
      themeColor={'primary'}
      size={'large'}
      onClick={onClick}>
      <div className={'d-flex'}>
        {isNewChat && <Icon name="circle" themeColor={'error'} />}
        <span
          className={'material-symbols-outlined'}
          style={{
            fontSize: '2rem',
            filter: 'drop-shadow(0px 3px 1px #00000029)'
          }}>
          {icon}
        </span>
      </div>
    </Button>
  );
};

export const Menu = ({
  isNewChat = false,
  handleOpenChat = () => {}
}: MenuProps) => {
  const [player] = useContext(PlayerContext);
  const [state] = useContext(GameDocumentContext);
  const [overlay, setOverlay] = useContext(OverlayContext);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isSmallDevice = useMediaQuery('(max-width:767px)');

  const isInLobby = player.playerState?.status === 'waiting';
  const preGameSetting = state.gameDocument?.settings.preGame;
  const inGameSetting = state.gameDocument?.settings.inGame;

  const showChatTab = isInLobby
    ? preGameSetting?.showPreGameChat
    : inGameSetting?.showInGameChat;

  const openChat = () => {
    setOverlay((prevState) => ({
      ...prevState,
      activeTab: 'chat',
      drawerIsOpen: true
    }));
    handleOpenChat();
  };

  const toggleOverlay = () => {
    setOverlay((prevState) => ({
      ...prevState,
      drawerIsOpen: !prevState.drawerIsOpen
    }));
  };

  const toggleOverlayMobile = () => {
    setOverlay((prevState) => ({
      ...prevState,
      drawerIsOpen: true,
      drawerMobileIsOpen: !prevState.drawerMobileIsOpen
    }));
  };

  const toggleProfileDialog = () =>
    setOverlay((prev) => ({
      ...prev,
      profileDialogIsOpen: !prev.profileDialogIsOpen
    }));

  const toggleButton = useMemo(() => {
    if (!isSmallDevice)
      return [{ icon: 'menu', onClick: toggleOverlay, isNewChat: undefined }];

    return overlay.drawerIsOpen
      ? [{ icon: 'expand_less', onClick: toggleOverlay, isNewChat: undefined }]
      : [];
  }, [isSmallDevice, overlay.drawerIsOpen]);

  const toggleButtonMobile = useMemo(
    () =>
      isSmallDevice
        ? [{ icon: 'menu', onClick: toggleOverlayMobile, isNewChat: undefined }]
        : [],
    [isSmallDevice]
  );

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  return (
    <>
      <div className={'w-100 position-absolute'}>
        <Container
          className={'px-3 px-xxl-0'}
          fluid
          style={{
            maxWidth: '1366px'
          }}>
          <Row className={'overflow-hidden container-header'}>
            <Col
              className={'d-flex header-left-menu align-content-center'}
              xs={12}
              md={'auto'}>
              <ProgressTimer />
            </Col>

            <Col
              xs={12}
              md={'auto'}
              className={
                'd-flex header-right-menu align-content-center align-items-center justify-content-end'
              }>
              {[
                { icon: 'notifications', onClick: toggleOverlay },
                ...(showChatTab
                  ? [{ icon: 'chat', onClick: openChat, isNewChat }]
                  : []),
                ...toggleButton,
                ...toggleButtonMobile
              ].map(({ icon, isNewChat, onClick }) => (
                <IconMenu
                  key={icon}
                  icon={icon}
                  onClick={onClick}
                  isNewChat={isNewChat}
                />
              ))}

              {!isSmallDevice && <PlayerAvatar onClick={toggleProfileDialog} />}

              <IconMenu
                icon={isFullscreen ? 'fullscreen_exit' : 'fullscreen'}
                onClick={toggleFullscreen}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
