import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import React, { useRef, useState, useEffect } from 'react';
import { useAssessment } from '../../../../hooks/use-assessment';
import { AssessmentControlLoading } from './loading';

const AssessmentControl = () => {
  const { gameCode } = useParams();
  const navigate = useNavigate();
  const assessment = useAssessment(gameCode!);

  const [status, setStatus] = useState(
    assessment.data?.assessmentStatus ?? 'NotStarted'
  );
  const endAssessmentButton = useRef<React.ElementRef<typeof Button>>(null);
  const [assessmentUrl, setassessmentUrl] = useState<string>('');

  useEffect(() => {
    let newUrl = `${window.location.origin}`;
    setassessmentUrl(`${newUrl}/facilitator/assessment/${gameCode}`);
  }, []);

  // assessment is loading
  if (assessment.isLoading) return <AssessmentControlLoading />;

  // assessment failed to load
  if (assessment.isError)
    return (
      <Row className={'align-items-center border-bottom py-3'}>
        <Col className={'fst-italic text-danger'}>
          There was an error loading the assessment for this event.
        </Col>
      </Row>
    );

  // no assessment found
  if (!assessment.data)
    return (
      <Row className={'align-items-center border-bottom py-3'}>
        <Col className={'fst-italic'}>
          There is no assessment for this event.
        </Col>
      </Row>
    );

  const statusClass = status === 'Running' ? 'text-success' : 'text-muted';

  /**
   * Ends the assessment and disables the end assessment button.
   *
   * @async
   * @function endAssessment
   * @returns {Promise} A promise that resolves when the assessment has ended.
   */
  const endAssessment = async () => {
    if (endAssessmentButton.current?.element)
      endAssessmentButton.current.element.disabled = true;
    setStatus('Ending...');
    await assessment.endAssessment();
  };

  return (
    <Row className={'align-items-center border-bottom py-3'}>
      <Col className={'fw-bold'}>Assessment:</Col>
      <Col xs={'auto'} className={statusClass}>
        {status}
      </Col>
      <Col xs={12} sm={'auto'}>
        <Button
          themeColor={'success'}
          className={'w-10 ml-2'}
          disabled={status !== 'NotStarted'}
          onClick={() => {
            window.open(assessmentUrl, '_blank');
          }}>
          Start assessment
        </Button>
        <Button
          ref={endAssessmentButton}
          themeColor={status === 'pre-game' ? 'base' : 'error'}
          className={'w-10 ml-2'}
          disabled={status !== 'Running'}
          onClick={endAssessment}>
          End assessment
        </Button>
      </Col>
    </Row>
  );
};

export default AssessmentControl;
export * from './error';
export * from './loading';
